.bcontainer {
    padding: 200px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-image: url('../../images/bg.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; */
    background: #0533a8;
    height: 100vh;
}

h4.title {
    margin: 0;
    margin-bottom: 20px;
    font-size: 28px;
    color: white;
}

.img_wrapper {
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.search_wrapper {
    background: #041d5c;
    color: white;
    padding: 20px;
    border-radius: 12px;
}

input.searchField {
    padding: 8px;
    border-radius: 14px;
    width: 94%;
    margin-bottom: 12px;
}

.btn_wrapper {
    display: flex;
    flex-wrap: nowrap;
    /* margin: 0 7px; */
}

p.text {
    margin: 4px 20px;
}

button.submitBtn {
    border-radius: 13px;
    width: 90px;
    color: white;
    background: #1b97db;
}
ul.suggestion {
    list-style: none;
    background: white;
    color: black;
    position: relative;
    top: -20px;
    padding: 20px;
}

li.suggest-child {
    padding: 6px;
    cursor: pointer;
}