.navbarhead {
    background: #004e9c;
    opacity: 0.7;
    padding: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.head_title {
    color: white;
    width: 70%;
    text-align: center;
    padding: 0;
}

button.btn-back {
    width: 60px;
    padding: 4px;
    color: white;
    background: #009be4;
}

.card_wrapper {
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    background: white;
    border-radius: 12px;
    margin: 20px 0;
}

.card_wrapper a {
    text-decoration: none;
    cursor: pointer;
    color: black;
}

@media only screen and (max-width: 600px) {
    .card_wrapper {
        width: 100px;
        height: 100px;
    }
}