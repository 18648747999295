.brand-container {
    display: grid;
    grid-template-columns: auto auto auto auto;
    justify-content: space-around;
    /* background-image: url('../../images/bg.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; */
    background: #0533a8;
    grid-column-gap: 25px;
    min-height: 100vh;
}

@media only screen and (max-width: 600px) {
    .brand-container {
        grid-template-columns: auto auto auto;
    }
    
  }